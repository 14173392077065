import React, { useState } from 'react'
import logo from "../assets/images/logo.svg"
import "../styles/index.scss"
import axios from "axios";
import { useEffect } from 'react';
const Footer = () => {
  const [tashkent, setTashkent] = useState(null)
  const [buhoro, setBuhoro] = useState(null)
  useEffect(() => {
    async function fetchData() {
      try {
        const responseTashkent = await axios.get(`https://uram.ddns.net/get_phone_support_by_city?city=Ташкент`)
        const responseBuhoro = await axios.get(`https://uram.ddns.net/get_phone_support_by_city?city=Бухара`)
        setTashkent(responseTashkent.data.PHONE_SUPPORT)
        setBuhoro(responseBuhoro.data.PHONE_SUPPORT)
      }
      catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [])

  return (
    <div className='App__footer'>
      <div className="App__footer_grid">
        <div className="App__footer_company">
          <img width={80} height={80} src={logo} alt="Logo" />
          <p>OOO "RADIUS-ETL"</p>
        </div>
        <div className="App__footer_contacts">
          <h1 className="h5 super-bold">Контакты</h1>
          <ul>
            <li><a href={`tel:+998951152200`}><span>Тех.поддержка в Ташкенте:+998951152200</span></a></li>
            <li><a href={`tel:+998934544216`}><span>Тех.поддержка в Бухаре:+998934544216</span></a></li>
          </ul>
        </div>
        <div className="App__foter_documents">
          <h1 className='h5 super-bold'>Документы</h1>
          <ul>
            <li><a href="https://uram.ddns.net/uram-privacy-policy/">Политика конфеденциальности</a></li>
            <li><a href="https://uram.ddns.net/uram-terms-of-user/">Пользовательское соглашение</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer